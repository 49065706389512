exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-apparel-js": () => import("./../../../src/pages/apparel.js" /* webpackChunkName: "component---src-pages-apparel-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cafe-awaji-js": () => import("./../../../src/pages/cafe/awaji.js" /* webpackChunkName: "component---src-pages-cafe-awaji-js" */),
  "component---src-pages-cafe-inagawa-js": () => import("./../../../src/pages/cafe/inagawa.js" /* webpackChunkName: "component---src-pages-cafe-inagawa-js" */),
  "component---src-pages-concept-js": () => import("./../../../src/pages/concept.js" /* webpackChunkName: "component---src-pages-concept-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact/success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-farm-js": () => import("./../../../src/pages/farm.js" /* webpackChunkName: "component---src-pages-farm-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pickup-js": () => import("./../../../src/pages/pickup.js" /* webpackChunkName: "component---src-pages-pickup-js" */),
  "component---src-pages-service-menu-js": () => import("./../../../src/pages/service/menu.js" /* webpackChunkName: "component---src-pages-service-menu-js" */),
  "component---src-pages-service-vibe-js": () => import("./../../../src/pages/service/vibe.js" /* webpackChunkName: "component---src-pages-service-vibe-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-pickup-post-js": () => import("./../../../src/templates/pickup-post.js" /* webpackChunkName: "component---src-templates-pickup-post-js" */)
}

